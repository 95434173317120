// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/AGUINALDO_DIC_WEB_landing_1.jpg";
import BannerMobile from "../imgs/AGUINALDO_DIC_WEB_mobile.jpg";
import { Card, Col, Container, Row } from "react-bootstrap";
import Logo_acciones from "../imgs/logos-fci/Logo_FCI_ACC.svg";
import Logo_renta_fija_dol from "../imgs/logos-fci/Logo_FCI_RF_DOL.svg";
import Slider from "react-slick";
import "./inverti-tu-aguinaldo.css";
import Faq from "../components/faq/faq";
import { apiRequest } from "../apiRquest/apiRequest";
import { graphql, useStaticQuery } from "gatsby";


const slickSettings = {
  centerMode: true,
  centerPadding: "0px",
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1, 
  dots: false,
  infinite: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1, // Actualiza también para mostrar de 1 en 1 en pantallas más pequeñas
      },
    },
  ],
};
const slickSettingsMobile = {
  centerMode: true,
  centerPadding: "0px",
  initialSlide: 0,
  slidesToShow: 2,
  slidesToScroll: 1, 
  dots: false,
  infinite: true,
  speed: 500,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1, // Actualiza también para mostrar de 1 en 1 en pantallas más pequeñas
      },
    },
  ],
};

const onsSlickSettings = {
  centerMode: true,
  centerPadding: "0px",
  initialSlide: 0,
  slidesToShow: 2,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const bannerDesc =
  '<h2 class="bannerDesc">Conocé todas las alternativas para invertir tus pesos y hacé rendir tu aguinaldo todo el año.</h2>';
const pageTitle = "Invertí tu aguinaldo | Balanz";
const pageDesc =
  "Conocé cómo podés invertir hoy tu aguinaldo en Balanz: Fondos Comunes de Inversión, Bonos, CEDEARs y cómo dolarizarte operando Dólar MEP.";

const Aguinaldo = () => {
  const FaqsData = useStaticQuery(graphql`
    {
      allFaqs(
        filter: { category_id: { in: 18 } }
        sort: { order: ASC, fields: [category_id, order] }
      ) {
        nodes {
          category_id
          id
          answer
          question
        }
      }
    }
  `);

  const [faq, setFaq] = useState({
    list: FaqsData.allFaqs.nodes,
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener("resize", handleResize);
    handleResize();
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    apiRequest
      .get("/v1/faq/18")
      .then((res) => {
        const data = [];
        res.data.data.forEach((d) => {
          data.push({
            question: d.question,
            answer: d.answer,
          });
        });
        setFaq((old) => ({ ...old, list: data }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setFaq]);
  const page = (
    <>
      <ProductBanner
        bannerContainerClassName="bannerAguinaldo"
        bannerTrasparecy={null}
        productTitle="<b><span class='underline'>¿En&nbsp;qué&nbsp;invertir&nbsp;</span><br class='d-inline d-sm-none' />el&nbsp;aguinaldo?<b>"
        productTitleClassName="bbottom"
        bannerImage={Banner}
        bannerImageClassName="eventosBannerImage"
        textContainerClassName="textContainer"
        bannerText={null}
        bannerTextClassName="bannerText1"
        bannerImageMobile={BannerMobile}
        mobile={isMobile}
        bannerDesc={bannerDesc}
        bannerImageStyle={{}}
      />
      {/*<SalWrapper>*/}
      <section className="text-center mt-5">
        <Container>
          <h2 style={{ color: "var(--primary)", textTransform: "none" }}>
          <strong>#Aguinaldo</strong>Todo<strong>El</strong>Año

          </h2>
        </Container>
      </section>
      <section className="fullwidth invertir-fondos-section product-description">
        <Container>
          <h2 className="text-left">
          2 Fondos Comunes de Inversión para potenciar tu aguinaldo en el largo plazo
          </h2>
           {
            !isMobile && (
                <Slider {...slickSettings} className="list-inversion">
           <div className='list-inversion-item'>
                                <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1 perfomance'>
                                            <img style={{maxWidth: "420px"}} src={Logo_acciones} className='obf-cover' alt="Fondo balanz acciones" />
                                        </Card.Title>
                                        <Card.Text className="mb-3 text-center">
                                        <strong>Invertí en acciones argentinas</strong>
                                        <br></br>
                                        <br></br>
                                        Busca maximizar el capital invirtiendo en <strong>acciones de las principales empresas argentinas,</strong> con el objetivo de superar al S&P Merval a largo plazo.
                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                            <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Variable
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: $1.000
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn btn-primary btn-hover' href='/inversiones/fondos/acciones/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVJbnN0cnVtZW50byIsDQoJImZvbmRvIjoiQkNBQ0MiLA0KCSJwYW5lbCI6IjEwIiwNCgkiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0ZDSUFDQ0lPTkVTLUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkFHVUlOQUxET0ZDSUFDQ0lPTkVTLUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiDQogIH19'>Invertir</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1'>
                                            <img style={{maxWidth: "420px"}} src={Logo_renta_fija_dol} alt="Renta fija en dolares" />
                                        </Card.Title>
                                        <Card.Text className="mb-3 text-center">
                                        <strong>Maximizá tu retorno en dólares</strong>
                                        <br></br>
                                        <br></br>
                                        Busca la apreciación del capital invirtiendo en una <strong>cartera diversificada de renta fija en dólares,</strong> incluyendo activos corporativos, soberanos y subsoberanos.
                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                        <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Fija
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: USD 100
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn thin btn-primary btn-hover' href='/inversiones/fondos/renta-fija-en-dolares/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVJbnN0cnVtZW50byIsDQoJImZvbmRvIjoiQkNSRG9sYXIiLA0KCSJwYW5lbCI6IjEwIiwNCgkiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0ZDSVJGVVNELUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkFHVUlOQUxET0ZDSVJGVVNELUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiDQogIH19'>Invertir</a>
                                        </div>


                                    </Card.Body>
                                </Card>
                            </div>
                           
            </Slider>
            )
           }
          {
            isMobile && (
                    
                <Slider {...slickSettingsMobile} className="list-inversion">
                  <div className='list-inversion-item'>
                            <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1 perfomance'>
                                            <img style={{maxWidth: "420px"}} src={Logo_acciones} className='obf-cover' alt="FCI Ahorro Dólares" />
                                        </Card.Title>
                                        <Card.Text className="mb-3 text-center">
                                        <strong>Invertí en acciones argentinas</strong>
                                        </Card.Text>
                                        <Card.Text className="mb-3 text-center">
                                        Busca maximizar el capital invirtiendo en <stron>acciones de las principales empresas argentinas,</stron> con el objetivo de superar al S&P Merval a largo plazo.
                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                            <ul className='list-data-2 mb-5'>
                                            <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Variable
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: $1.000
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn btn-primary btn-hover' href='/inversiones/fondos/acciones/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVJbnN0cnVtZW50byIsDQoJImZvbmRvIjoiQkNBQ0MiLA0KCSJwYW5lbCI6IjEwIiwNCgkiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0ZDSUFDQ0lPTkVTLUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkFHVUlOQUxET0ZDSUFDQ0lPTkVTLUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiDQogIH19'>Invertir</a>
                                        </div>
                                    </Card.Body>
                                </Card>
                           
                            </div>
                   <div className='list-inversion-item'>
                                <Card className='card-inversion pesos'>
                                    <Card.Body>
                                        <Card.Title className='mb-1'>
                                            <img style={{maxWidth: "420px"}} src={Logo_renta_fija_dol} alt="Perfomance II" />
                                        </Card.Title>
                                        <Card.Text className="mb-3 text-center">
                                        <strong>Maximizá tu retorno en dólares</strong>
                                        </Card.Text>
                                        <Card.Text className="mb-3 text-center">
                                        Busca la apreciación del capital invirtiendo en una <strong>cartera diversificada de renta fija en dólares,</strong> incluyendo activos corporativos, soberanos y subsoberanos.
                                        </Card.Text>
                                        <Card.Text className="mb-3">
                                        <ul className='list-data-2 mb-5'>
                                                <li>
                                                    <span><strong>Tipo de Fondo</strong></span>: Renta Fija
                                                </li>
                                                <li>
                                                    <span><strong>Inversión mínima</strong></span>: USD 100
                                                </li>
                                            </ul>
                                        </Card.Text>
                                        <div className="btn-slider">
                                            <a target='_blank' className='btn-custom-2 btn thin btn-primary btn-hover' href='/inversiones/fondos/renta-fija-en-dolares/'>Ver más</a>
                                            <a target='_blank' className='btn-custom btn thin btn-secondary ' href='https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6IHsNCiAgICAicmVxdWllcmVsb2dpbiI6IjEiLA0KCSJuYW1lc3BhY2UiOiJDb3JlLkJ1c2luZXNzLkRldGFsbGVJbnN0cnVtZW50byIsDQoJImZvbmRvIjoiQkNSRG9sYXIiLA0KCSJwYW5lbCI6IjEwIiwNCgkiaWRDYW1wYWlnbiI6IkFHVUlOQUxET0ZDSVJGVVNELUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiLA0KCSJjYW1wYWlnbl9pZCI6IkFHVUlOQUxET0ZDSVJGVVNELUZDSS1XRUItMjAyNDEyMDUtREVFUExJTksiDQogIH190'>Invertir</a>
                                        </div>


                                    </Card.Body>
                                </Card>
                            </div>
                            
                            
                </Slider>
                    
            )
          }
        </Container>
      </section>

      
      <section className="fullwidth obligaciones-negociables-section pb-3">
        <Container>
          <div className="section-content py-2">
            <p className="volanta" style={{ color: "var(--secondary)" }}>
              MERCADO SECUNDARIO
            </p>
            {/*<h6 className="topic base-text">MERCADO SECUNDARIO</h6>*/}
            <h2 className="titulo mb-5">
              <span style={{ color: "var(--primary)" }}>
                Obligaciones Negociables en Dólares
              </span>
            </h2>

            <Slider
              {...onsSlickSettings}
              className="slider-obligaciones-negociales"
            >
              <div className="slider-item">
                <Card className="card-obligaciones-negociables">
                  <Card.Header>
                    <Card.Title as="h3">
                      Invertí en <br />
                      Pan American Energy 
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p className="base-text-custom mb-2">
                      <strong>
                        {" "}
                        Destacada empresa energética de América Latina, enfocada en la exploración, producción y comercialización de petróleo y gas.

                      </strong>
                    </p>
                    <ul className="mb-4">
                      <li clasName="base-text-custom">
                        <strong>Perfil:</strong> Conservador
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ticker:</strong> PN37O
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Clase:</strong> 37
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Vencimiento:</strong> 13/11/2028
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Cupón:</strong> 6.25%
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Pago:</strong> Semestral
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Moneda:</strong> Dólares
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ley:</strong> Argentina
                      </li>
                    </ul>

                    <a
                      href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJQTjM3TyIsInBhbmVsIjoiMTYiLCJwbGF6byI6IjEiLCJpZFBsYXpvIjoiMSIsImlkQ2FtcGFpZ24iOiJBR1VJTkFMRE9PTlBBRS1PTi1XRUItMjAyNDEyMDUtREVFUExJTksiLCJjYW1wYWlnbl9pZCI6IkFHVUlOQUxET09OUEFFLU9OLVdFQi0yMDI0MTIwNS1ERUVQTElOSyJ9fQ"
                      target="blank"
                      className="btn-link"
                    >
                      Invertir&nbsp;&nbsp;
                    </a>
                  </Card.Body>
                </Card>
              </div>
              <div className="slider-item">
                <Card className="card-obligaciones-negociables">
                  <Card.Header>
                    <Card.Title as="h3">
                      Invertí en <br />
                      Telecom Argentina

                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <p className="base-text-custom mb-2">
                      <strong>
                      Empresa argentina líder en telecomunicaciones que brinda servicios de telefonía fija, móvil, internet, y TV por suscripción, con presencia regional en Paraguay y Uruguay.
                      </strong>
                    </p>
                    <ul className="mb-4">
                      <li clasName="base-text-custom">
                        <strong>Perfil:</strong> Moderado
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ticker:</strong> TLCMO
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Clase:</strong> 21
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Vencimiento:</strong> 18/07/2031
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Cupón:</strong> 9.50%
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Pago:</strong> Semestral
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Moneda:</strong> Dólares
                      </li>
                      <li clasName="base-text-custom">
                        <strong>Ley:</strong> New York
                      </li>
                    </ul>

                    <a
                      href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlSW5zdHJ1bWVudG8iLCJ0aWNrZXIiOiJUTENNTyIsInBhbmVsIjoiMTYiLCJwbGF6byI6IjEiLCJpZFBsYXpvIjoiMSIsImlkQ2FtcGFpZ24iOiJBR1VJTkFMRE9PTlRFTEVDT00tT04tV0VCLTIwMjQxMjA1LURFRVBMSU5LIiwiY2FtcGFpZ25faWQiOiJBR1VJTkFMRE9PTlRFTEVDT00tT04tV0VCLTIwMjQxMjA1LURFRVBMSU5LIn19"
                      target="blank"
                      className="btn-link"
                    >
                      Invertir&nbsp;&nbsp;
                    </a>
                  </Card.Body>
                </Card>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
      <section className="fullwidth invertir-aguinaldo">
        <Container>
          <div className="section-content py-5">
            <h2 className="titulo mb-3">
              <span style={{ color: "#ffffff"}}>
              Evento Online: ¿En qué invertir el aguinaldo?
              </span>
            </h2>
            <h5 className="mb-2">
              <span style={{ color: "#ffffff"}}>
              Conocé las mejores alternativas para que tu aguinaldo rinda durante todo el año. 
              </span>
            </h5>
            
          </div>
          <a
            className="btn-custom btn thin btn-secondary"
            target="_blank"
            href="https://www.youtube.com/watch?v=pL4PXHdeTqg"
          >
            Ver más
          </a>
        </Container>
      </section>

      <section className="mt-5 mb-2">
        <Container>
          <h2 className="text-left" style={{ color: "var(--secondary" }}>
          Armá tu portfolio a largo plazo invirtiendo en el mundo a través de CEDEARs
          </h2>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey">
        <Container>
          <div className="section-content">
          <Row>
                  
                  <Col md={6}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                   PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)"}}>
                   Tesla (TSLA)
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  >Compañía dirigida por Elon Musk, cofundador de muchas otras grandes empresas, que diseña, fabrica y vende productos de almacenamiento de energía y vehículos totalmente eléctricos de alto rendimiento.
                  </span>
                </p>
                  </Col>
                  <Col md={6}
                  className="d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                 <a
                  className="btn-custom btn thin btn-secondary"
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiVFNMQSIsDQoJInBhbmVsIjoiNyIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6IkFHVUlOQUxET1RTTEEtQ0VERUFSLVdFQi0yMDI0MTIwNS1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiQUdVSU5BTERPVFNMQS1DRURFQVItV0VCLTIwMjQxMjA1LURFRVBMSU5LIg0KICB9fQ"
                >
                  Invertir
                </a>
                  </Col>
                </Row>
          </div>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey mt-4">
        <Container>
          <div className="section-content">
          <Row>
                  
                  <Col md={6}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                    PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)"}}>
                   Qualcomm (QCOM)
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  > Empresa de tecnología inalámbrica proveedora de circuitos integrados con Apple y Samsung como mayores clientes.
                  Fue pionero y tuvo un rol fundamental en el desarrollo del 3G y 4G y lo está teniendo en el 5G.
                  
                  
                  </span>
                </p>
                  </Col>
                  <Col md={6}
                  className="d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                 <a
                  className="btn-custom btn thin btn-secondary"
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=ew0KICAiZGF0YSI6ew0KCSJyZXF1aWVyZWxvZ2luIjoiMSIsDQoJIm5hbWVzcGFjZSI6IkNvcmUuQnVzaW5lc3MuRGV0YWxsZUluc3RydW1lbnRvIiwNCgkidGlja2VyIjoiUUNPTSIsDQoJInBhbmVsIjoiNyIsDQoJInBsYXpvIjoiMSIsDQoJImlkUGxhem8iOiIxIiwNCgkiaWRDYW1wYWlnbiI6IkFHVUlOQUxET1FDT00tQ0VERUFSLVdFQi0yMDI0MTIwNS1ERUVQTElOSyIsDQoJImNhbXBhaWduX2lkIjoiQUdVSU5BTERPUUNPTS1DRURFQVItV0VCLTIwMjQxMjA1LURFRVBMSU5LIg0KICB9fQ"
                >
                  Invertir
                </a>
                  </Col>
                </Row>
          </div>
        </Container>
      </section>
      <section className="mt-5 mb-2">
        <Container>
          <h2 className="text-left" style={{ color: "var(--secondary" }}>
          Aguinaldo Invertido = Aguinaldo que rinde todo el año
            <br></br><span><strong>Invertí en Packs de CEDEARs</strong></span>
          </h2>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo bg-lt-grey">
        <Container>
          <div className="section-content">
            <div className="content-section">
                <Row>
                  
                  <Col md={12}>
                    <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
                   PERFIL AGRESIVO
                 </p>
                 <h2 className="titulo mb-md-4">
                   <span style={{ color: "var(--primary)"}}>
                   Pack Quant Selection  
                   </span>
                 </h2>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "600",
                      fontSize: " 123%",
                    }}
                  >
                    Una estrategia desarrollada bajo estrictos criterios cuantitativos para crear un portfolio óptimo. Utilizando criterios que surgen de la historia de precios, rendimientos y volatilidades de las compañías que lo integran.
                    Su objetivo es seleccionar CEDEARs de empresas líderes de diversas industrias cuya relación entre retorno y volatilidad sea mejor al del índice S&P 500 en un periodo determinado. 
                  </span>
                </p>
                  </Col>
                  </Row>
            </div>
            <div className="mt-5">
              <Row>
                  <Col md={12}
                  className="d-flex flex-column align-items-center justify-content-center justify-content-md-end"
                  >
                  <a
                  className="btn-custom btn thin btn-secondary "
                  target="_blank"
                  href="https://clientes.balanz.com/link?data=eyJkYXRhIjp7InJlcXVpZXJlbG9naW4iOiIxIiwibmFtZXNwYWNlIjoiQ29yZS5CdXNpbmVzcy5EZXRhbGxlUGFjayIsInRpY2tlciI6IlBBQ0stIFFVQU5UIHwgUGVzb3MgQ0VERUFSIiwicGFuZWwiOiIzOCIsInBsYXpvIjoiMSIsImlkUGxhem8iOiIxIiwiaWRDYW1wYWlnbiI6IkFHVUlOQUxET1FVQU5UU0VMRUNUSU9OLVBBQ0stV0VCLTIwMjQxMjA1LURFRVBMSU5LIiwiY2FtcGFpZ25faWQiOiJBR1VJTkFMRE9RVUFOVFNFTEVDVElPTi1QQUNLLVdFQi0yMDI0MTIwNS1ERUVQTElOSyJ9fQ"
                > Invertir </a>
                  </Col>
                  
                </Row>
              </div>
          </div>
        </Container>
      </section>
      <section className="fullwidth banner-cedears-aguinaldo">
        <Container>
          <div className="section-content">
            <div className="content-section">
                <Row>
                <p className="volanta-moderado" style={{ color: "var(--secondary)" }}>
          DOLARIZARTE ES SIMPLE
          </p>
          <h2 className="titulo mb-md-4">
                  <span style={{ color: "var(--primary)"}}>
                  Comprá Dólar MEP
                  </span>
                 </h2>
                  <Col md={12}>
                    <p  style={{ 
                      color: "var(--primary)", 
                      fontWeight: "900",
                      fontSize: "1.6rem",
                      }}>
                          Convertí tus pesos en dólares, de forma rápida y segura
                 </p>
                  <p className=" my-4 my-md-0 primary-color">
                  <span
                    style={{
                      lineHeight: "1.25",
                      maxWidth: "540px",
                      fontWeight: "500",
                      fontSize: " 123%",
                    }}
                  >
                    Con un solo click, podés iniciar tu inversión y aprovechar esta oportunidad financiera única. 
                    Dólar MEP se ha convertido en una de las opciones más populares para dolarizar ahorros, 
                    debido a la accesibilidad y seguridad. Operar en Dólar MEP es una forma conveniente y 
                    confiable de manejar tus recursos y dolarizarte.


                  </span>
                </p>
                  </Col>
                  </Row>
                  <Row>
                  <Col md={12}
                  className="d-flex align-items-center justify-content-center mt-3"
                  >
                  <a
                  className="btn-custom btn thin btn-secondary "
                  target="_blank"
                  href="https://clientes.balanz.com/auth/login"
                > Invertir </a>
                  </Col>
                </Row>
            </div>
          </div>
        </Container>
      </section>
     
      <section className="fullwidth preguntas-frecuentes">
        <Container>
          <Row>
            <Faq faq={faq} customClass={"aguinaldo-faq"} />
          </Row>
          <p className="mb-4">
            El destinatario deberá evaluar por sí mismo la conveniencia de la
            inversión en los presentes valores negociables.
          </p>
          <p className="mb-4">
            Balanz Capital Valores S.A.U. y/o Balanz Sociedad Gerente de Fondos
            Comunes de Inversión S.A.U. no brindan garantías ni aseguran
            rentabilidades ni resultados de los presentes valores negociables.
            Para mayor información, contáctese con nuestros asesores.
          </p>
        </Container>
      </section>
    </>
  );

  return (
    <main>
      <Layout
        title={pageTitle}
        description={pageDesc}
        childrem={page}
        category={"aguinaldos"}
      ></Layout>
    </main>
  );
};

// Step 3: Export your component
export default Aguinaldo;
